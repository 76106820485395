import { likeRegExp } from '@/offline/database'

export default {
  async _addSubqueriesAlmacenesParte (Vue, rows) {
    for (let row of rows) {
      const tables = Vue.$offline.db.tables
      row.stock = (await Vue.$offline.db
        .select(Vue.$offline.db.fn.count().as('count'))
        .from(tables.stock)
        .where(
          Vue.$offline.db.op.and(
            tables.stock.idalmacen.eq(row.idalmacen),
          )
        ).exec())[0]
    }
    return rows
  },
  _selectAlmacenesParteBase (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.v_almacenes_parte)
      .orderBy(tables.v_almacenes_parte.descripcion)
  },
  async selectAlmacenesParte (Vue, filter, search, sorter, page, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    let where = []
    let whereSearch = []
    let whereFilter = []
    if (search) {
      const reQ = likeRegExp(search)
      whereSearch.push(
        Vue.$offline.db.op.or(
          tables.v_almacenes_parte.descripcion.match(reQ),
        )
      )
    }
    where.push(
      Vue.$offline.db.op.and(
        tables.v_almacenes_parte.idparte_trabajo.eq(idparteTrabajo),
        tables.v_almacenes_parte.estado.gt(0),
        ...whereSearch,
        ...whereFilter
      )
    )
    const rows = await this._selectAlmacenesParteBase(Vue)
      .where(...where)
      .limit(Vue.$offline.db.ITEMS_PER_PAGE)
      .skip(page * Vue.$offline.db.ITEMS_PER_PAGE)
      .exec()
    return [await this._addSubqueriesAlmacenesParte(Vue, rows)]

  },
  async selectAlmacenesParteRows (Vue, pks) {
    const tables = Vue.$offline.db.tables
    const rows =  await this._selectAlmacenesParteBase(Vue)
      .where(tables.v_almacenes_parte.idv_almacenes_parte.in(pks))
      .exec()
    return [await this._addSubqueriesAlmacenesParte(Vue, rows)]
  },
}
